import styled from 'styled-components'
import { breakpoints } from '../../helpers/media'
import { DEFAULT_COLOR, DEFAULT_GREY_TITLE } from '../../helpers/colors'

export const H1 = styled.h1(
  ({ underline, color = 'white' }) => `
  color: ${color};
  font-size: 95px;
  letter-spacing: 0px;
  text-align: center;
  text-shadow: 0px 3px 6px #000000be;
  opacity: 1;
  @media only screen and (max-width: ${breakpoints.md}px) {
    font-size: 55px;
  }
  transition: font-size 0.3s ease-in-out;
  ::after {
    content: '';
    display: block;
    margin-left: 35%;
    margin-top: 10px;
    width: 30%;
    height: ${underline && '5.5px'};
    background: ${color};
    transition: width 0.3s;
    transition-delay: 0.1s;
  }
`
)

export const H3 = styled.h3(
  ({ underline, color, textShadow }) => `
  width: fit-content;
  position: relative;
  margin: 50px !important;
  font-size: 50px;
  letter-spacing: 0px;
  opacity: 1;
  transition: font-size 0.3s ease-in-out;
  color: ${color || DEFAULT_GREY_TITLE};
  text-shadow: ${textShadow || '0px 2px 3px #0000001f'};
  text-align: center;
  @media only screen and (max-width: ${breakpoints.md}px) {
    font-size: 30px;
    margin: 20px !important;
    ::after {
      margin-top: 5px;
    }
  }
`
)

export const H4 = styled.h4(
  ({ underline, color, textShadow, margin = '50px', small }) => `
  width: fit-content;
  position: relative;
  margin: ${margin};
  font-size: ${small ? 22 : 32}px;
  letter-spacing: 0px;
  opacity: 1;
  transition: font-size 0.3s ease-in-out;
  color: ${color || '#4c4d4f'};
  text-shadow: ${textShadow || '0px 2px 3px #0000001f'};
  text-align: center;
  @media only screen and (max-width: ${breakpoints.md}px) {
    font-size: 16px;
  }
  ::after {
    top: 60px;
    content: '';
    display: block;
    margin-left: 35%;
    margin-top: 10px;
    width: 30%;
    height: ${underline && '3.5px'};
    background: ${color || '#4c4d4f'};
    transition: width 0.3s;
    transition-delay: 0.1s;
  }
`
)

export const BreadWrapper = styled.div(
  ({
    row = false,
    backgroundColor,
    color,
    textShadow,
    maxWidth,
    fontSize,
    alignItems,
    noPad = false
  }) => `
  display: flex;
  flex-direction: ${row ? 'row' : 'column'};
  align-items: ${alignItems || 'center'};
  justify-content: center;
  width: 100%;
  max-width: ${maxWidth || '100%'};
  padding: ${noPad ? '' : '0px 30px 0px 30px'};
  letter-spacing: 0.2px;
  text-align: left;
  font: Regular 30px/30px Kefa;
  background-color: ${backgroundColor || 'transparent'};
  color: ${color || 'rgba(247, 244, 244, 1)'};
  text-shadow: ${textShadow && '0px 2px 3px #00000014'};
  opacity: 1;
  font-size: 14px;
  @media only screen and (min-width: ${breakpoints.md}px) {
    font-size: ${fontSize || '21px'};
    line-height: 32px;
  }
  &h1, h2, h3, h4, h5 {
    margin-top: 15px;
    margin-bottom: 15px;
  }
`
)
