import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100vw;
  padding-top: 150px;
`
const AlumnHero = () => {
  return <Wrapper></Wrapper>
}

export default AlumnHero
