import React from 'react'
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs'
import Img from 'gatsby-image'
import { BreadWrapper } from '../../../components/common/fonts'
import { breakpoints } from '../../../helpers/media'
import { DEFAULT_COLOR, DEFAULT_GREY_TITLE } from '../../../helpers/colors'
import useTranslation from '../../../hooks/use-translation'
import CustomRichText from '../../../components/custom-rich-text'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 100vw;
  overflow: hidden;
  padding: 15px;
`

const SectionTitle = styled.a`
  color: ${DEFAULT_GREY_TITLE};
  text-align: center;
  margin-bottom: 50px;
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  @media only screen and (min-width: ${breakpoints.md}px) {
    font-size: 50px;
    margin-bottom: 75px;
  }
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  @media only screen and (min-width: ${breakpoints.md}px) {
    flex-direction: row;
  }
`

const TextWrapper = styled.div`
  & p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 3px 0px 3px 0px;
  }
  @media only screen and (min-width: ${breakpoints.md}px) {
    width: 40vw;
    margin-right: 5vw;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`
const Title = styled.h3`
  color: #4c4d4fe5;
`

const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 600px;
  max-width: 85vw;
  @media only screen and (min-width: ${breakpoints.md}px) {
    width: 40vw;
    max-width: 500px;
  }
`
const AlumnSection = ({ type, title, text, url, image }) => {
  const t = useTranslation()
  return (
    <Wrapper>
      <SectionTitle href={url} target='_blank'>
        {t`Apply here to become`} {t(`${type}`)}!
      </SectionTitle>
      <InnerWrapper>
        <TextWrapper>
          <TitleWrapper>
            <Title>{RichText.asText(title)}</Title>
          </TitleWrapper>
          <BreadWrapper alignItems='flex-start' noPad color='#4C4D4FE5'>
            <CustomRichText richText={text} />
          </BreadWrapper>
        </TextWrapper>
        <ImgWrapper>
          {image && <Img style={{ width: '100%' }} fluid={image} />}
        </ImgWrapper>
      </InnerWrapper>
    </Wrapper>
  )
}

export default AlumnSection
