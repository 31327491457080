import React, { useEffect, useState } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { graphql } from 'gatsby'
import useLanguage from '../hooks/use-language'
import { getNode } from '../helpers/graph-helper'
import AlumnSection from '../views/alumner/alumn-section'
import AlumnHero from '../views/alumner/alumn-hero'

const Alumner = ({ data, location }) => {
  const [asMentor, setAsMentor] = useState(true)
  useEffect(() => {
    if (location.hash) {
      setAsMentor(!location.hash.includes('alumn'))
    }
  }, [location.hash])
  const language = useLanguage()
  let node = getNode(language.locale, data, 'allAlumners')

  return (
    <Layout headerBg>
      <SEO title='Alumner' />
      <AlumnHero />
      {asMentor ? (
        <AlumnSection
          type={asMentor ? 'a mentor' : 'an adept'}
          title={node.mentor_title}
          text={node.mentor_text}
          url={node.mentor_link.url}
          image={node.mentor_imageSharp && node.mentor_imageSharp.childImageSharp.fluid}
        />
      ) : (
        <AlumnSection
          type={asMentor ? 'a mentor' : 'an adept'}
          title={node.adept_title}
          text={node.adept_text}
          url={node.adept_link.url}
          image={node.adept_imageSharp && node.adept_imageSharp.childImageSharp.fluid}
        />
      )}
    </Layout>
  )
}

export const query = graphql`
  {
    prismic {
      allAlumners {
        edges {
          node {
            _meta {
              lang
            }
            mentor_link {
              ... on PRISMIC__ExternalLink {
                _linkType
                url
              }
            }
            mentor_title
            mentor_text
            mentor_image
            mentor_imageSharp {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1000) {
                 ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            adept_link {
              ... on PRISMIC__ExternalLink {
                _linkType
                url
              }
            }
            adept_title
            adept_text
            adept_image
            adept_imageSharp {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1000) {
                 ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Alumner
